<template>
  <div class="vld-parent">
    <loading
        :active="isLoading"
        :can-cancel="cancancel"
        :on-cancel="onCancel"
        :is-full-page="fullPage"
        loader="dots"
        background-color="#000000"
        color="#00FFFF"
        :lock-scroll=true
        blur="10px"
    />
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
export default {
    props:['isLoading','cancancel','onCancel','fullPage'],
    components: {
        Loading,
    },
};
</script>