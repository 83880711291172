import store from '../store'

export const fetchUser = () => {
  return new Promise(async (resolve, reject) => {
    const token = localStorage.getItem('Token')

    if (token) {
      const user = JSON.parse(localStorage.getItem('User'))

      const details = {
        ...user,
        data: {
          token
        }
      }

      store.commit('login', { loginSuccess: true, details })
      return resolve('success')
    } else {
      resolve('no-token')
    }
  })
}
