<template>
  <div class="join">
      <div class="join_box">
          <div class="heading">
            <h1>Join the team</h1>  
          </div>
          <img src="../assets/image/invest-chart.png" alt="">
          <div class="btn_div">           
                <a href="https://forms.gle/GLoq3mwPaVxcpbts8" target="_blank">Apply</a>           
                <!-- <a href="https://forms.gle/skvvL1wg3bWQ1pf16" target="_blank">Apply</a>            -->
        </div>
      </div>
  </div>
</template>

<script>
export default {
    // -next-line vue/multi-word-component-names
    name:'Careers',
}
</script>
<style lang="postcss" scoped>
.join{ @apply flex justify-center;
    .join_box{ @apply mb-10;
        .heading{ @apply flex flex-wrap w-full mb-10  items-center justify-center text-center;
            h1{ @apply text-6xl font-bold py-4 text-transparent bg-clip-text bg-gradient-to-br from-invbtn to-invbtn2;
            }  
        }   
    }       
    img{ @apply m-auto w-[14.938rem] mb-16;
    }
    .btn_div{ @apply text-center mt-7;
        a{ @apply inline-flex text-black font-semibold bg-gradient-to-br from-invbtn to-invbtn2 border-0 py-5 px-20 focus:outline-none rounded text-lg;
        &:hover{
                        @apply hover:bg-gradient-to-br from-hover to-hover;
                }
        }
    }
}

</style>