<template>
  <div class="terms_main_div">
      <div class="condition_div">
      <h1 class="main_heading">Declaration of Risk</h1> 
      <p>By accessing/using the Fredo platform through the website [link] (“Website”), you bear the fitness to undertake the risks in investments through the Website including but not limited to the following:</p>
    </div>
    <div class="condition_div">
      <p>1. Loss of Capital : Investments in ventures/brand(s) bear an inherent risk of not assuring full-fledged profits or returns from the investments, since these Brands may not have a business model or established concept which can be used as a reference for 100% success. Therefore, it is very crucial for the Investor to analyse and gauge the risks that are involved with investing in a Brand and shall contemplate and be familiar with any and all shortfalls that may arise including unexpected problems in the areas of product development, manufacturing, marketing, financing, and general management that a Brand often experiences.</p>
    </div>
    <div class="condition_div">
      <p>2. Lack of Liquidity: An Investor's investments will generally be private, illiquid holdings. As such, there may be no readily available liquidity mechanism available for any of the investments. In addition, an investment in a Brand will not be freely transferable, and involves a high degree of risk and low liquidity and should be viewed as aggressive long-term investments. There is no public market for securities in a Brand and it is not expected that a public market will develop. Consequently, an Investor will bear the economic risks of its investment until such time as it holds securities in the Brand. This means that the Investor is likely to see a return only through monthly revenue share repayments (only in case of non-convertible debt instruments).</p>
    </div>
    <div class="condition_div">
      <p>3. Rarity of Dividends: The Brands may most likely be unable to pay any dividend throughout the life cycle of an equity investment. Therefore, in order for you to earn a return out of any of your equity investments, you will have to go through a further sale or such other similar process for which a time frame cannot be ascertained.</p>
    </div>
    <div class="condition_div">
      <p>4. Dilution: The Brands may raise additional capital in the future and therefore, if you have equity investments it may be diluted, as a result of such issue of new shares.</p>
    </div>
    <div class="condition_div">
      <p>5. Performance: The forward-looking statements of Brands, containing opinions and beliefs, are based on a number of estimates and assumptions that are subject to significant business, economic, environmental, regulatory, and competitive uncertainties. Though these statements can be used for understanding the objectives and goals of the Brands, such statements should not be considered as undertakings from the Brands and should be considered as merely being speculative and having subjective nature.</p>
    </div>
    <div class="condition_div">
      <p>6. Tax: You may be liable to pay taxes on any dividends or gains you receive from your investments in the Brands and payment of such taxes is entirely your responsibility. Therefore, you should consult your tax advisor for more information on these matters.</p>
    </div>
    <div class="condition_div">
      <p>7. Difficulty in Pricing and Valuing Brand Investments: It is difficult to determine objective valuations for any securities. In addition to the difficulty of determining the magnitude of the risks applicable to a given Brand, there generally will be no readily available secondary market for a Brand's securities, and hence, an investor's investments may be difficult to exit before the tenure of the investment.</p>
    </div>
    <div class="condition_div">
      <p>8. Regulatory Risks: The investment may be subject to applicable laws of the Investor's country of residence as well as the country where the relevant Brand is registered. The growth in the revenue of the Brand may depend on the regulatory environment and a change in applicable laws to the Brand or the Investor may adversely affect the returns on the investment, the ability of a Brand to succeed or the ability of an Investor to recover or obtain returns on his investment. The Investors are advised to seek adequate legal advice to familiarise themselves in this relation, prior to making investments in any Brand.</p>
    </div>
    <div class="condition_div">
      <p>9. The Company providing the present platform is only a facilitator for investment and is a platform offering opportunities for investment purely restricted to investors registered on the platform and is not open to the general public.</p>
    </div>
    <div class="condition_div">
      <p>10. The Company is not carrying on the business of “Investment Banking”, “Merchant Banking”, “Broking”, “Financial Advisory Services”, “Credit Rating” “Investment Trustee” etc. as regulated by the Securities and Exchange Board of India (SEBI) under the SEBI Act and Rules/ Regulations framed under SEBI Act.</p>
    </div>
    <div class="condition_div">
      <p>11. The Company through this platform aims to provide Investors with all the requisite information relating to the investment opportunities presented on the platform to enable the Investors to arrive at an informed decision by themselves. The platform does not recommend any investment by itself to the Investors.</p>
    </div>
    <div class="condition_div">
      <p>12. Nothing contained on the platform should be construed as an authorization by SEBI to solicit investments or provide advice on investment nor should the platform be construed as a SEBI authorized crowd-funding platform, or a fund raising platform, or a stock exchange recognised by the Securities and Exchange Board of India under the Securities Contract (Regulation) Act, 1956, or their equivalent.</p>
    </div>
    <div class="condition_div">
      <p>13. All decisions on Investment are to be taken by the Investors on their own independent judgement and the platform does not accept any responsibility of any kind as to the future outcome of the investment which are purely market dependent and the Company/platform does not guarantee any returns on investment.</p>
      <p>The foregoing risks are only indicative in nature. It is recommended that you seek independent legal and tax advice and read the relevant investment documents carefully before investing in a Brand. For the avoidance of doubt, in light of your acknowledgment of the above risk factors, you agree and acknowledge that you shall hold Fredo harmless and shall not raise any claim in respect of any of the above.</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="postcss" scoped>
.terms_main_div{ @apply container;
  .condition_div{ @apply my-8;
  .main_heading{ @apply text-borderclr mb-2 font-normal text-[1.9rem];}
    .sub_heading{ @apply text-borderclr mb-2 font-normal text-[1.2rem];
    }
    p{ @apply text-whitergb mb-3 flex;
     span{ @apply mr-2;}
     strong{ @apply text-black;
     }
    }
  }
}
</style>