<template>
<div class="mt-8">
  <!-- invest header -->
  <div class="head_sticky resp_continr">
    <div class="invest_head">
      <div class="w-full">

        <div>
            <img :alt=startupName :src="startupLogo" />
        </div>
        <div class="prodc_name">
          <div>

            <span>
            <span class="head_text">{{ startupName }}</span>
            <span class="head_text_2">by {{ StartupCompanyName }}</span>
          </span>
          </div>
        </div>
      </div>
      <div>

      <router-link to="/payment" class="invest_btn rep_hidn" v-show="daysleft != 0" @click.prevent="storeStartupId">Subscribe</router-link >
      </div>
    </div>
  </div>
  <!-- invest header end-->

  <!-- youtube and invest money section -->
  <section class="resp_continr">
    <div class="main_div">
      <div class="youtube_frm">
        <!-- you tube iframe -->
        <iframe
          width="570"
          height="380"
          :src="startupyoutubeLink"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
        <!-- you tube iframe end -->
      </div>
      <div class="progres_main_div">
        <!-- progres bar -->
        <div class="progres_detail">
          <span class="span_1">{{fixPercentage(percentage)}}%</span>
          <p v-if="this.daysleft != 0">
            <span class="span_3">Closes in :</span> {{daysleft+' Days'}}
          </p>
          <p v-else>Closed</p>
        </div>
        <div class="progres_bar_point">
          <div class="progres_bar" :style="widthReturn(percentage)"></div>
        </div>
        <!-- progres bar end-->
        <!-- raise,goal,investment,valuation sction -->
        <div class="wrapr_main">
          <div class="wrapper">
            <!--raise card 1 -->
            <div class="invest_opt_div">
              <h1 class="head_txt">{{ startupheighlight1title }}</h1>
              <span class="amount">{{ startupheighlight1content }}</span>
            </div>
            <!--raise card 1 end -->
            <!--goal card 2-->
            <div class="invest_opt_div">
              <h1 class="head_txt">{{ startupheighlight2title }}</h1>
              <span class="amount">{{ startupheighlight2content }}</span>
            </div>
            <!--goal card 2 end-->
          </div>
          <div class="wrapper">
            <!--investment card 3-->
            <div class="invest_opt_div">
              <h1 class="head_txt">{{ startupheighlight3title }}</h1>
              <span class="amount">{{ startupheighlight3content }}</span>
            </div>
            <!--investment card 3 end-->
            <!--valuation card 4-->
            <div class="invest_opt_div4">
              <h1 class="head_txt">{{ startupheighlight4title }}</h1>
              <span class="amount">{{ startupheighlight4content }}</span>
            </div>
            <!--valuation card 4 end-->
          </div>
        </div>
        <!-- raise,goal,investment,valuation section end-->
      </div>
    </div>
  </section>
  <!-- youtube and invest money section end -->

  <!-- sidebar with content -->
  <div class="notes_main_div" v-if="this.$store.getters.loginState">
    <div class="w-full text-center sticky bottom-0 order-1 bg-navbg z-10 md:hidden xl:hidden">
   <router-link to="/payment" class="btn-Div resp_scon_btn_hide my-2 text-center" v-show="daysleft != 0" @click.prevent="storeStartupId">Subscribe</router-link>
  </div>
    <!-- sidebar -->
    <div class="sidebar">
      <!-- nav -->
      <div class="side_menu_list">
        <nav>
          <a v-for="detailsNav in startupdetailContent" :key="detailsNav.id" :href="'#' + detailsNav.id">{{ detailsNav.title }}</a>
        </nav>
        <!-- nav -->
        <!-- button -->
        <router-link to="/payment" class="invest_btn" @click.prevent="storeStartupId" v-show="daysleft != 0">Subscribe</router-link>
        <!-- button end -->
      </div>
    </div>
    <!-- sidebar end-->
    <!-- content -->
    <div class="content_detail resp_continr">
      <div v-for="startupdetails in startupdetailContent" :key="startupdetails.id">
        <!--Highlights heading -->
        <div class="heading scroll-mt-20" :id="startupdetails.id"  >
          <h1>{{ startupdetails.title }}</h1>
        </div>
        <!--Highlights heading end -->
        <!--Highlights details  -->
        <div class="text">
          <div v-if="startupdetails.startup_detail_content != null" v-html="startupdetails.startup_detail_content.description">
          </div>
          <div v-else>
            <div class="team_main_div">
              <div class="team_flx">
                  <div class="pro_div" v-for="teamStartup in startupdetails.startup_detail_content_image" :key="teamStartup.id">
                    <div class="pro_detil_div ">
                      <div class="profil_img">
                      <img :src="baseURL(teamStartup.image)">
                      </div>
                      <h2>{{teamStartup.name}}</h2>
                      <span class="leading-relaxed">
                        {{teamStartup.designation}}
                      </span>
                      <p></p>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Highlights details end -->
    </div>
  </div>
  <!-- sidebar with content end -->

  <div class="login_div" v-else>
    <div class="login_innr_div">
      <p>Login to view full details</p>
      <div v-show="!moblie" v-if="!loggedIn" class="rightBx">
        <router-link to="/login" class="login_btn">Login</router-link>
      </div>
    </div>
  </div>
</div>

</template>

<script>
import axios from "axios";
export default {
  name: "Startup",
  data() {
    return {
      showModal: false,
      startupLogo: "",
      startupName: "",
      StartupCompanyName: "",
      startupyoutubeLink: "",
      startupMininvest: "",
      startupFundinggoal: "",
      startupheighlight1title: "",
      startupheighlight1content: "",
      startupheighlight2title: "",
      startupheighlight2content: "",
      startupheighlight3title: "",
      startupheighlight3content: "",
      startupheighlight4title: "",
      startupheighlight4content: "",
      startupdetailContent: [],
      percentage:"",
      daysleft:"",
      isLoading: true
    };
  },
  methods:{
    storeStartupId(){
      localStorage.setItem('startupId',this.$route.params.id);
    },
    fixPercentage(value) {
        return Math.round(value);
    },
    widthReturn(value){
      return "width:"+value+"%";
    },
    baseURL(value){
        return "https://admin.fredoinvest.com/images/startup/teamuser/"+value;
    },
  },
  async created() {
    localStorage.removeItem('startupId');
    this.$store.commit("setGlobalLoadingState", true)
    await axios
      .get("startup-list/" + this.$route.params.id)
      .then((response) => {
        this.$store.dispatch("startUpDetails", response.data.data);
        this.startupLogo =
          process.env.VUE_APP_ASSETS_STARTUP_logo_URL +
          response.data.data.startup_detail.logo;
        this.startupName = response.data.data.startup_detail.name;
        this.StartupCompanyName = response.data.data.company_name;
        this.startupyoutubeLink =
          "https://www.youtube.com/embed/" +
          response.data.data.startup_detail.youtube_id;
        this.startupMininvest =
          response.data.data.startup_detail.min_investment;
        this.startupFundinggoal =
          response.data.data.startup_detail.min_investment;
        this.startupheighlight1title =
          response.data.data.startup_detail.highlight_1;
        this.startupheighlight1content =
          response.data.data.startup_detail.highlight_1_content;
        this.startupheighlight2title =
          response.data.data.startup_detail.highlight_2;
        this.startupheighlight2content =
          response.data.data.startup_detail.highlight_2_content;
        this.startupheighlight3title =
          response.data.data.startup_detail.highlight_3;
        this.startupheighlight3content =
          response.data.data.startup_detail.highlight_3_content;
        this.startupheighlight4title =
          response.data.data.startup_detail.highlight_4;
        this.startupheighlight4content =
          response.data.data.startup_detail.highlight_4_content;
        this.startupdetailContent =
          response.data.data.startup_detail.stapup_detail_content_title;
          this.percentage = response.data.percentage;
          this.daysleft = response.data.diff_in_days;
          localStorage.setItem('startLeadId',response.data.data.id);
          this.$store.commit("setGlobalLoadingState", false)
      })
      .catch((err) => {
        this.$toast.open({
          message: err,
          type: "error",
          duration: 5000,
          dismissible: true,
        });
        this.$store.commit("setGlobalLoadingState", false)
      });
  },
};
</script>

<style lang="postcss" scoped>
.heading {
  @apply flex flex-wrap justify-start items-start w-full mb-0 flex-col text-left;
  h1 {
    @apply text-5xl pb-0 font-bold text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
  }
}
.invest_btn {
  @apply inline-flex text-black font-bold bg-gradient-to-br from-clr6 to-clr7  border-0 py-2 px-12 focus:outline-none  rounded-[1rem] text-lg;
  &:hover{
            @apply hover:bg-gradient-to-br from-hover to-hover;
    }
}
.head_sticky{@apply sticky top-0 pt-4 pb-2 bg-navbg w-full z-10;
  .invest_head {
    @apply container flex justify-between items-center my-0;
    div {
      @apply flex;
      img {
        @apply w-16 h-16 rounded-xsm  flex-shrink-0;
      }
      .prodc_name { @apply items-center;
        .head_text {
          @apply text-clr1 text-sm font-bold font-Poppins;
        }
        .head_text_2 {
          @apply text-menutext text-xxxs font-Poppins font-extrabold;
        }
      }
    }
    span {
      @apply flex-grow flex flex-col pl-3;
    }
  }
}
.kyc_card {
  @apply p-6 max-w-sm bg-fotxthd rounded-lg m-auto text-center;
  p {
    @apply mb-8 text-base font-bold text-black;
  }
  a {
    @apply inline-flex items-center bg-cardbg2 py-2 px-3 text-sm font-medium text-center text-borderclr rounded-[0.5rem];
    svg {
      @apply mr-3;
    }
  }
}
.main_div {
  @apply container flex flex-wrap px-5 pt-11 gap-[29px] mx-auto;
  .youtube_frm {
    @apply  mb-10;
    iframe{ @apply rounded-[0.8rem]}
  }
  .progres_main_div {
    @apply flex flex-col md:w-1/2 md:pl-4;
    .progres_detail {
      @apply flex justify-between mb-1 items-center;
      .span_1 {
        @apply text-sm font-medium text-borderclr font-Poppins;
      }
      p {
        @apply text-sm font-medium text-borderclr font-Poppins;
        .span_3 {
          @apply text-clr2;
        }
      }
    }
    .progres_bar_point {
      @apply w-full h-7 bg-cardbg2 rounded-full;
      .progres_bar {
        @apply h-7 bg-borderclr rounded-full;
        @apply max-w-full
      }
    }
    .wrapr_main {
      @apply mt-10;
      .wrapper {
        @apply flex justify-between mb-11;
        .invest_opt_div {
          @apply h-full bg-cardbg2 bg-opacity-75 flex-col justify-center items-center w-[46%] py-5  rounded-xsm overflow-hidden text-center relative;
          .head_txt {
            @apply text-lg font-medium text-menutext mb-3 font-Poppins;
          }
          .amount {
            @apply sm:text-2xl text-4xl font-extrabold text-fotxthd font-Poppins mb-3;
          }
        }
        .invest_opt_div4 {
          @apply h-full bg-cardbg2 bg-opacity-75 flex-col justify-center items-center w-[46%] py-5 rounded-xsm overflow-hidden text-center relative;
          .head_txt {
            @apply text-lg font-medium text-menutext mb-3 font-Poppins;
          }
          .amount {
            @apply sm:text-2xl text-4xl font-extrabold text-fotxthd font-Poppins mb-3;
          }
        }
      }
    }
  }
}
.notes_main_div {
  @apply container relative min-h-screen md:flex;
  .sidebar {
    @apply text-blue-100 w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform -translate-x-full md:relative md:translate-x-0 transition duration-200 ease-in-out;
    .side_menu_list {
      @apply sticky top-28;
      nav {
        @apply text-left mb-10;
        a {
          @apply block py-3 px-4 rounded-full transition duration-200 hover:bg-cardbg2 hover:text-borderclr font-Poppins text-lg text-menutext;
          &.active {
            @apply bg-cardbg2 text-borderclr;
          }
        }
      }
    }
  }
  .content_detail {
    @apply flex-1 text-2xl font-bold;
    .heading2 {
      @apply flex flex-wrap w-full mt-8 mb-10 flex-col text-left;
      h1 {
        @apply text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
      }
    }
    .text { @apply text-white font-Poppins text-[1.2rem] font-thin leading-[34px];
      p {
        @apply text-white font-Poppins text-lg font-thin;
        span {
          @apply mr-4;
        }
      }
      div{
        ol{
          li{
           @apply list-decimal;
          }
        }
      }
    }
    .team_main_div {
      @apply container  py-8 mx-auto;
      .team_flx {
        @apply flex flex-wrap -m-4 justify-between;
        .pro_div {
          @apply lg:w-1/4 lg:mb-8;
          .pro_detil_div {
            @apply h-full text-center;
            .profil_img{
              img { @apply w-28 h-28 mb-4 object-cover object-center rounded-xfull inline-block border-[0.147rem] border-gradient bg-gray-100;
              }
            }
            h2 {
              @apply text-white font-medium tracking-wider text-2xl;
            }
            span {
              @apply text-white opacity-75 text-base font-thin;
            }
            p {
              @apply text-white opacity-75 text-xxxxs font-thin;
            }
          }
        }
      }
    }
  }
}
.login_div{
    @apply bg-cardbg2 w-full h-[400px] text-center flex justify-center items-center;
    .login_innr_div{
        @apply py-8;
        p{
            @apply text-4xl font-bold mb-8 text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
        }
        .rightBx{
            .login_btn{ @apply  lg:inline-block bg-navbg  text-xs px-9 py-2  border-2 rounded-[1rem] text-menutext border-borderclr lg:mt-0 hover:text-white;
            }
        }
    }
}
@media (min-width: 320px) and (max-width: 552px) {
  .heading2 {
    @apply mb-3 !important;
    h1 {
      @apply text-[1.5rem] mr-0 !important;
    }
  }
  .heading {
    @apply text-left justify-start mb-3 !important;
    h1 {
      @apply text-[1.5rem] text-left !important;
    }
  }
}
</style>
