<template>
  <Header :loggedIn="loggedIn" />
  <router-view></router-view>
  <Footer />
  <PageLoader :isLoading="this.$store.getters.globalLoadingState" />
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'
import PageLoader from './components/PageLoading.vue'

export default {
  name: 'App', 
  components: {
    Header,
    Footer,
    PageLoader
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loginState
    }
  }, 
}
</script>
