<template>
  <!-- Startuplist -->
  <div class="startuplist_list_card">
    <router-link :to="{ path: '/startup/' + startupLink }">
      <div class="card">
        <figure>
          <img :src="startupThumbnail" :alt="startupName" />
        </figure>
        <div class="card_info_main_div">
          <div class="card_info_inr_div">
            <div class="icon_with_name">
              <img :alt="startupName" :src="startupLogo" />
              <span>{{ startupName }}</span>
            </div>
            <p>
              {{ startupShortDesc }}
            </p>
          </div>
          <div class="flag_main_div">
            <div class="flag">
              <span>{{ startupSector }}</span>
            </div>
          </div>
          <div class="statusofStartup">
            <span>
              <b>Raised</b>
              <b v-show="leftdays != 'Closed'">Closes in</b>
            </span>
            <span>
              <strong>{{ rasied }}%</strong>
              <strong>{{ leftdays }}</strong>
            </span>
            <div class="statup_bar_point">
              <div class="statup_progres_bar" :style="`width:${rasied}%`"></div>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </div>
  <!-- Startuplist end -->
</template>

<script>
export default {
  // -next-line vue/multi-word-component-names
  name: 'Startuplist',
  props: [
    'startupLink',
    'startupThumbnail',
    'startupName',
    'startupLogo',
    'startupShortDesc',
    'startupSector',
    'rasied',
    'leftdays'
  ]
}
</script>
<style lang="postcss" scoped>
.startuplist_list_card {
  @apply w-full md:w-1/2 lg:w-1/3 pl-5 px-10 mb-14;
  .card {
    @apply bg-cardbg3 flex flex-col rounded-[1.5rem] p-5 h-full transition-all hover:scale-105;
    figure {
      @apply mb-2;
      img {
        @apply h-48 object-cover w-full rounded-[1.5rem];
      }
    }
    .card_info_main_div {
      @apply flex flex-col justify-between h-full py-3;
      .card_info_inr_div {
        @apply w-full mb-4 overflow-hidden;
        .icon_with_name {
          @apply flex items-center mb-4;
          img {
            @apply w-11 h-11 rounded-[0.632rem] flex-shrink-0;
          }
          span {
            @apply text-white text-base font-medium ml-4;
          }
        }
        p {
          @apply text-white bg-opacity-75 mb-5;
        }
      }
      .flag_main_div {
        @apply flex items-center;
        .flag {
          @apply text-lg text-white font-light;
          span {
            @apply bg-cardbtnbg   text-white text-xxxs py-1 px-4 rounded-xsm mr-2;
          }
        }
      }
      .statusofStartup {
        @apply pt-4;
        span {
          @apply flex justify-between;
          strong {
            @apply text-white text-xs font-medium;
          }
          b {
            @apply text-[#a8a7a7] text-xxxs font-medium;
          }
        }
        .statup_bar_point {
          @apply w-full h-2 mt-5 bg-cardbg2 rounded-full;
          .statup_progres_bar {
            @apply h-2 bg-borderclr w-1/2 rounded-full;
            @apply max-w-full;
          }
        }
      }
    }
  }
}
</style>
