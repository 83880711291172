<template>
  <div class="dashboard">
    <Sidebar />
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Sidebar from '../../components/Sidebar.vue'
export default {
  name: 'home',
  components: {
    Sidebar
  }
}
</script>

<style lang="postcss" scoped>
.dashboard {
  @apply relative my-10 grid grid-cols-1;
  grid-template-columns: 1fr 5fr;
  .content {
    @apply bg-clr9 rounded-tl-[1.5rem] rounded-bl-[1.5rem];
  }
}
</style>
