<template>
  <div>
    <!-- heading -->
    <div class="heading" id="form">
      <h1>Empower {{ startupName }}</h1>
    </div>
    <!-- heading end -->
    <!-- form -->
    <div class="form_main_div resp_pymt" v-if="paymentBx">
      <form class="form">
        <!-- enter amount-->
        <div class="column">
          <label class="">Amount</label>
          <input
            class="focus:outline-none focus:shadow-outline"
            type="number"
            id="xyz"
            placeholder="Enter a number"
            v-model="investAmount"
          />
        </div>
        <div class="column_flx">
          <div v-for="amount in this.getInvestmentSlabs" :key="amount">
            <span
              class="amount-tab"
              :id="amount"
              data-disable="disable"
              @click.prevent="additionammount(amount)"
            >
              + &#8377; {{ amount }}
            </span>
          </div>
        </div>
        <div class="discount_div">
          <p v-if="!showModal">
            Have a discount code? <span @click="showModal = true">Use now</span>
          </p>
          <div class="column applyCopoun btn_div" v-else>
            <input
              class="focus:outline-none focus:shadow-outline"
              maxlength="100"
              id="discount_code"
              type="text"
              placeholder="Discount code"
              v-model="couponCode"
              :disabled="this.couponapplied"
            />
            <span @click.prevent="applyCouponCode" v-if="disabledApplyCode">
              Apply
            </span>
            <span @click.prevent="removeCoupon" v-else> Remove </span>
          </div>
        </div>
        <!-- enter amount end-->
        <!-- amont detail -->
        <div class="amount_detail">
          <div class="particulars">
            <ul>
              <li>
                <h1>Particulars</h1>
              </li>
              <li>
                <p>Subscription amount</p>
              </li>
              <li>
                <p>Convenience Fee</p>
              </li>
              <li v-if="couponapplied">
                <p>Coupon Discount({{ couponCodepercent }}%)</p>
              </li>
              <li>
                <p>GST (18%)</p>
              </li>

              <li>
                <h1>Total</h1>
              </li>
            </ul>
          </div>
          <div class="amount">
            <ul>
              <li>
                <h1>Amount</h1>
              </li>
              <li>
                <p>{{ formatPrice(investAmount) }}</p>
              </li>
              <li>
                <p>{{ formatPrice(conveniencefee) }}</p>
              </li>
              <li v-if="couponapplied">
                <p>{{ '- ' + formatPrice(couponCodeAmount) }}</p>
              </li>
              <li>
                <p>{{ formatPrice(gst) }}</p>
              </li>
              <li>
                <h1>{{ formatPrice(finalTotal) }}</h1>
              </li>
            </ul>
          </div>
        </div>
        <!-- amont detail end -->
        <!-- payment button -->
        <div class="pymt_div">
          <div class="btn_div">
            <span @click.prevent="offlinePaymentMethod" class="btn_2">
              Pay offline
            </span>
            <p>NEFT, RTGS, Bank transfer</p>
          </div>
          <div class="btn_div">
            <span
              class="btn_2"
              @click.prevent="payOnline"
              :disabled="isPayBtnDisabled"
            >
              Pay online
            </span>
            <p>Payment Gateway</p>
          </div>
        </div>
        <!-- payment button end -->
      </form>
    </div>
    <!-- form end -->
    <!-- offline payment -->
    <div class="offline_form_main_div" v-if="offlinepayment">
      <!-- heading -->
      <div class="form_heading" id="form">
        <h1>Use the details below to subscribe using RTGS, NEFT or IMPS</h1>
      </div>
      <!-- heading end -->
      <form class="form">
        <!--offline amount detail -->
        <div class="offline_amount_detail">
          <div class="particulars">
            <ul>
              <li>
                <p>Account holder :</p>
              </li>
              <li>
                <p>Bank Name :</p>
              </li>
              <li>
                <p>IFSC Code :</p>
              </li>
              <li>
                <p>Account number :</p>
              </li>
              <li>
                <p>Amount :</p>
              </li>
            </ul>
          </div>
          <div class="amount">
            <ul>
              <li>
                <p>{{ bankholder }}</p>
              </li>
              <li>
                <p>
                  {{ bankName }}
                </p>
              </li>
              <li>
                <p>{{ accountNumber }}</p>
              </li>
              <li>
                <p>{{ ifsccode }}</p>
              </li>
              <li>
                <p>{{ formatPrice(finalTotal) }}</p>
              </li>
            </ul>
          </div>
        </div>
        <!--offline amount detail end -->
        <!-- note -->
        <div class="note">
          <p>
            You will receive an invoice on your registered email address once
            the subscription is reflected in the above account
          </p>
        </div>
        <!-- note end -->
        <!-- payment button -->
        <div class="offline_pymt_div">
          <div class="btn_div">
            <button class="btn_1">Close</button>
          </div>
          <div class="btn_div">
            <button class="btn_2">Confirm</button>
          </div>
        </div>
        <!-- payment button end -->
      </form>
    </div>
    <!-- offline payment end -->
  </div>
</template>

<script>
import axios from 'axios'
export default {
  /*  vue/multi-word-component-names */
  name: 'payment',
  data() {
    return {
      showModal: false,
      startupName: '',
      isLoading: true,
      investAmount: '',
      conveniencefee: 0,
      conveniencefeeAPI: 0,
      gstAPI: '',
      gst: '',
      rotations: 4,
      finalTotal: '',
      minInvest: '',
      offlinepayment: false,
      paymentBx: true,
      couponCode: '',
      disabledApplyCode: true,
      accountNumber: '',
      bankholder: '',
      ifsccode: '',
      bankName: '',
      couponCodeAmount: 0,
      couponapplied: false,
      isPayBtnDisabled: false,
      couponCodepercent: '',
      coupon_type: ''
    }
  },
  methods: {
    formatPrice(value) {
      return '₹ ' + Number(value).toLocaleString()
    },
    calculateInvestamount(e) {
      this.conveniencefee = (2 * this.investAmount) / 100
      this.gst = (this.conveniencefee * 18) / 100

      this.conveniencefeeAPI = (2 * this.investAmount) / 100
      this.gstAPI = (this.conveniencefeeAPI * 18) / 100

      if (this.couponapplied) {
        this.couponCodeAmount =
          this.conveniencefee * (this.couponCodepercent / 100)

        this.gst = ((this.conveniencefee - this.couponCodeAmount) * 18) / 100
        this.gstAPI = this.gst * (this.couponCodepercent / 100)
        this.conveniencefeeAPI = this.conveniencefee - this.couponCodeAmount

        if (this.conveniencefee === this.conveniencefeeAPI) {
          this.gstAPI = 0
          this.conveniencefeeAPI = 0
        }
      }

      const totalInvestment =
        parseFloat(this.investAmount) +
        parseFloat(this.conveniencefee) +
        parseFloat(this.gst)

      if (!isNaN(totalInvestment)) {
        this.finalTotal = totalInvestment - this.couponCodeAmount
      } else {
        this.finalTotal = '0'
      }
    },
    async additionammount(addamount) {
      if (this.investAmount != '') {
        this.investAmount =
          parseFloat(this.investAmount) + parseFloat(addamount)
      } else {
        this.investAmount = addamount
      }

      if (this.couponCode) {
        await this.applyCouponCode()
      }
    },
    async offlinePaymentMethod() {
      this.isLoading = true
      if (this.investAmount != '') {
        await axios
          .post(
            'offline-funding',
            {
              investor_id: this.$store.state.user.id,
              startup_lead_id: this.$store.state.startUpDetails.id,
              startup_detail_id:
                this.$store.state.startUpDetails.startup_detail.id,
              investment_amount: this.investAmount,
              convenience_fee: this.conveniencefeeAPI,
              gst_charges: this.gstAPI,
              payment_type: 'offline',
              investment_id:
                this.$store.state.startUpDetails.startup_detail
                  .api_investment_id,
              coupon_code: this.couponCode
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token') //the token is a variable which holds the token
              }
            }
          )
          .then((response) => {
            if (response.data.status == 200) {
              this.isLoading = false
              this.offlinepayment = true
              this.paymentBx = false
              this.accountNumber = response.data.data.acccount_number
              this.bankholder =
                this.$store.state.startUpDetails.startup_detail.company_legal_name
              this.bankName = response.data.data.bank_name
              this.ifsccode = response.data.data.ifsc_code
            }
          })
          .catch((err) => {
            this.$toast.open({
              message: err,
              type: 'error',
              duration: 5000,
              dismissible: true
            })
          })
      } else {
        this.$toast.open({
          message: 'Please add Subscription amount',
          type: 'error',
          duration: 5000,
          dismissible: true
        })
      }
    },
    async payOnline() {
      if (this.investAmount < this.minInvest) {
        this.$toast.open({
          message: `Minimum amount to subscribe is ${this.minInvest}`,
          type: 'warning',
          duration: 5000,
          dismissible: true
        })

        return
      }

      this.$store.commit('setGlobalLoadingState', true)
      this.isPayBtnDisabled = true

      const body = {
        investor_id: this.$store.state.user.id,
        startup_lead_id: this.$store.state.startUpDetails.id,
        startup_detail_id: this.$store.state.startUpDetails.startup_detail.id,
        investment_amount: this.investAmount,
        convenience_fee: this.conveniencefeeAPI,
        gst_charges: this.gst,
        payment_type: 'online',
        coupon_code: this.couponCode
      }

      await axios
        .post('funding', body, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('Token')
          }
        })
        .then((response) => {
          if (response.data.status == 200) {
            localStorage.setItem('inv-details', JSON.stringify(body))

            if (response.data.redirect) {
              window.location.href = response.data.data.payment_url
              this.$store.commit('setGlobalLoadingState', false)
            } else {
              this.$store.commit('setGlobalLoadingState', false)

              this.$toast.open({
                message: response.data.msg,
                type: 'success',
                duration: 5000,
                dismissible: true
              })
            }
          } else {
            this.$store.commit('setGlobalLoadingState', false)

            this.$toast.open({
              message: response.data.msg,
              type: 'error',
              duration: 5000,
              dismissible: true
            })
          }
        })
        .catch((err) => {
          this.$store.commit('setGlobalLoadingState', false)

          this.$toast.open({
            message: err,
            type: 'error',
            duration: 5000,
            dismissible: true
          })
        })
    },
    async applyCouponCode() {
      this.isLoading = true

      if (this.investAmount !== 0) {
        await axios
          .post(
            'checkcoupon',
            {
              investor_id: this.$store.state.user.id,
              coupon_code: this.couponCode
            },
            {
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('Token') //the token is a variable which holds the token
              }
            }
          )
          .then((response) => {
            if (response.data.status == 200) {
              this.isLoading = false
              this.disabledApplyCode = false
              this.couponapplied = true

              if (response.data.data.coupon_type === 'percentage') {
                this.couponCodepercent = response.data.data.amount
                this.coupon_type = response.data.data.coupon_type
                this.couponCodeAmount =
                  this.conveniencefee * (response.data.data.amount / 100)
                this.couponCodeAmount = this.couponCodeAmount + this.gst
                this.calculateInvestamount()
              } else {
                this.couponCodeAmount = response.data.data.amount
              }
              this.$toast.open({
                message: response.data.msg,
                type: 'success',
                duration: 5000,
                dismissible: true
              })

            } else {
              this.isLoading = false
              this.$toast.open({
                message: response.data.msg,
                type: 'error',
                duration: 5000,
                dismissible: true
              })
            }
          })
          .catch((err) => {
            this.isLoading = false
            this.$toast.open({
              message: err,
              type: 'error',
              duration: 5000,
              dismissible: true
            })
          })
      } else {
        this.$toast.open({
          message: 'Please enter Subscription amount',
          type: 'error',
          duration: 5000,
          dismissible: true
        })
      }
    },
    removeCoupon() {
      this.couponCode = ''
      this.calculateInvestamount()
      this.disabledApplyCode = true
    },
    couponCodebx() {
      if (this.couponCode.length) {
        this.disabledApplyCode = false
      }
    }
  },
  mounted() {
    window.addEventListener('keyup', function (event) {
      // const amounts = document.querySelector('[data-disable]')
      // if (amounts && amounts.length) {
      //   for (let i = 0; i < amounts.length; i++) {
      //     amounts[i].setAttribute("disabled", "true")
      //   }
      // }
    })
  },
  watch: {
    couponCode(newVal, oldVal) {
      if (!newVal) {
        this.couponapplied = false
        this.couponCodeAmount = 0
        this.calculateInvestamount()
      }
    },
    investAmount(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calculateInvestamount()
      }
    }
  },
  computed: {
    getInvestmentSlabs() {
      let investmentSlabs = []

      for (let i = 0; i < this.rotations; i++) {
        let amount = this.minInvest + 5000 * i
        investmentSlabs.push(amount)
      }

      return investmentSlabs
    }
  },
  // updated() {
  //   this.calculateInvestamount();
  // },
  async created() {
    // GET request using axios with async/await
    ;(this.isLoading = true),
      await axios
        .get('startup-list/' + localStorage.getItem('startupId'))
        .then((response) => {
          this.startupName = response.data.data.startup_detail.name
          this.minInvest = response.data.data.startup_detail.min_investment
          this.$store.dispatch('startUpDetails', response.data.data)
          this.isLoading = false
        })
        .catch((err) => {
          this.$toast.open({
            message: err,
            type: 'error',
            duration: 5000,
            dismissible: true
          })
        })
  }
}
</script>

<style lang="postcss" scoped>
.heading {
  @apply flex flex-wrap w-full mb-10 flex-col items-center text-center;
  h1 {
    @apply text-5xl font-semibold py-4 text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
  }
}
@media (min-width: 766px) and (max-width: 934px) {
  .heading h1 {
    @apply text-3xl font-bold !important;
  }
}
@media (min-width: 320px) and (max-width: 552px) {
  .heading h1 {
    @apply text-3xl font-bold !important;
  }
}
.form_main_div {
  @apply max-w-screen-md rounded-lg bg-cardbg2 mx-auto p-14 my-20 mb-40;
  .form {
    @apply w-full;
    .column {
      @apply w-full  px-16 mb-6 md:mb-0;
      label {
        @apply block capitalize  text-clr3 text-2xl font-medium mb-2;
      }
      input {
        @apply w-full bg-cardbg3 text-white  p-3 rounded-lg;
      }
    }
    .column_flx {
      @apply w-full flex justify-between items-center px-16 mb-6 md:mb-0 my-6;
      .amount-tab {
        @apply rounded-xsm bg-cardbg3 py-2 px-4 text-white opacity-75 tracking-wider font-thin cursor-pointer;
      }
      button {
        @apply rounded-xsm bg-cardbg3 py-2 px-4 text-white opacity-75 tracking-wider font-thin;
      }
    }
    .discount_div {
      @apply my-4;
      .applyCopoun {
        @apply flex;
      }
      .btn_div {
        @apply text-center;
        span {
          @apply inline-flex text-black ml-2 font-semibold bg-gradient-to-br from-clr6 to-clr7 border-0 py-3 px-6 focus:outline-none rounded-[1rem] text-lg cursor-pointer;
          &:hover {
            @apply hover:bg-gradient-to-br from-hover to-hover;
          }
        }
      }
      p {
        @apply text-base text-center  text-menutext;
        span {
          @apply text-borderclr cursor-pointer;
        }
      }
    }
    .amount_detail {
      @apply flex w-full justify-between bg-cardbg3 rounded-full  px-16 py-10 mb-6 md:mb-0 mb-10;
      ul {
        li {
          @apply py-3;
          h1 {
            @apply text-2xl text-clr3 font-medium capitalize mb-2;
          }
          p {
            @apply text-lg text-white opacity-90;
          }
          span {
            @apply text-xxxxs text-white opacity-75;
          }
        }
      }
      .amount {
        @apply text-right;
      }
    }
    .pymt_div {
      @apply flex justify-evenly items-center mb-20;
      .btn_div {
        @apply text-center mt-7;
        .btn_1 {
          @apply inline-flex text-menutext font-medium bg-cardbg3 border-0 py-3 px-12 focus:outline-none rounded text-lg;
        }
        .btn_2 {
          @apply inline-flex text-black font-medium bg-gradient-to-br from-invbtn to-invbtn2 border-0 py-3 px-12 focus:outline-none rounded text-lg cursor-pointer;
          &:hover {
            @apply hover:bg-gradient-to-br from-hover to-hover;
          }
        }
        p {
          @apply text-white text-xxxxs mt-1;
        }
      }
    }
  }
}
.offline_form_main_div {
  @apply max-w-screen-md rounded-lg bg-cardbg2 mx-auto px-20 my-20 mb-40;
  .form_heading {
    @apply flex flex-wrap w-full mb-10 pt-10 flex-col items-center text-center;
    h1 {
      @apply text-2xl font-semibold py-4 text-transparent bg-clip-text bg-gradient-to-br from-invbtn to-invbtn2;
    }
  }
  .form {
    .offline_amount_detail {
      @apply flex w-full justify-evenly bg-cardbg3 rounded-full py-10  my-20;
      ul {
        li {
          @apply py-3;
          p {
            @apply text-lg text-white opacity-90;
          }
        }
      }
    }
    .note {
      @apply bg-clr3 rounded-full p-5 my-8 text-center;
      p {
        @apply text-xxs text-cardbg3 font-medium;
      }
    }
    .offline_pymt_div {
      @apply flex justify-evenly items-center pb-36;
      .btn_div {
        @apply text-center mt-7;
        .btn_1 {
          @apply inline-flex text-white font-medium bg-cardbg3 border-0 py-3 px-12 focus:outline-none rounded text-lg;
        }
        .btn_2 {
          @apply inline-flex text-black font-medium bg-gradient-to-br from-invbtn to-invbtn2 border-0 py-3 px-12 focus:outline-none rounded text-lg;
        }
        p {
          @apply text-white text-xxxxs mt-1;
        }
      }
    }
  }
}
</style>
