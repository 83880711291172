import { createStore } from 'vuex'
import router from '../router'
import axios from 'axios'

export default createStore({
  state: {
    token: null,
    isLoggedIn: false,
    iskyc: false,
    user: [],
    globalLoadingState: false,
    kycdetails: [],
    getStepKyc: '',
    startUpDetails: [],
    paymentProcessed: null,
  },
  getters: {
    loginState: (state) => {
      return state.isLoggedIn
    },
    kycStatus: (state) => {
      return state.iskyc
    },
    getStepKyc: (state) => {
      return state.getStepKyc
    },
    startUpState: (state) => {
      return state.startUpDetails
    },
    globalLoadingState: (state) => {
      return state.globalLoadingState
    },
    getPaymentProcessed(state) {
      return state.paymentProcessed
    }
  },
  mutations: {
    setPaymentProcessed(state, isProcessed) {
      state.paymentProcessed = isProcessed
    },
    setGlobalLoadingState(state, isLoading) {
      state.globalLoadingState = isLoading
    },
    login(state, info) {
      if (info.loginSuccess === false) {
        state.isLoggedIn = false
        state.user = {}
        state.token = null
        localStorage.removeItem('Token')
        localStorage.removeItem('User')
      } else {
        state.isLoggedIn = true
        state.user = info.details
        state.token = info.details.data.token

        localStorage.setItem('Token', info.details.data.token)
        localStorage.setItem('User', JSON.stringify(info.details))

        if (state.user.kyc_status == 1) {
          state.iskyc = true
          state.kycdetails = info.details.basic_detail
        } else {
          state.iskyc = false
        }
      }
    },
    logout(state) {
      state.isLoggedIn = false
      state.user = []
      state.loginUser = []
      state.kycdetails = []
      state.getStepKyc = ''
      localStorage.setItem('userLogin', 'false')
      localStorage.clear()
      router.push({ name: "Login" })
    },
    getStepKyc(state, Abovestep) {
      state.getStepKyc = Abovestep
    },
    startUpDetails(state, startup) {
      state.startUpDetails = startup
    }
  },
  actions: {
    async handleMobileOTP({ commit }, mobileNumber) {
      return new Promise(async (resolve, reject) => {
        if (/^[6-9]\d{9}$/.test(mobileNumber)) {
          await axios
            .post('login', {
              phone: mobileNumber
            })
            .then((response) => {
              if (response.data.status == 200) {
                resolve(response.data)
              } else {
                resolve()
              }
            })
            .catch((err) => {
              reject(new Error(err))
            })
        } else {          
          reject(new Error('Please enter valid phone number.'))
        }
      })
    },
    async login({ commit }, info) {
      return new Promise(async (resolve, reject) => {
        await axios
          .post('genrateToken', {
            phone: info.mobileNumber,
            otpcode: info.otpcode
          })
          .then((response) => {
            if (response.data.status == 200) {
              resolve(response.data)
              commit('login', { loginSuccess: true, details: response.data })
            } else {
              resolve()
            }
          })
          .catch((err) => {
            reject(new Error(err))
          })
      })
    },
    logout({ commit }) {
      commit('logout')
    },
    getStepKyc({ commit }, Abovestep) {
      commit('getStepKyc', Abovestep)
    },
    startUpDetails({ commit }, startup) {
      commit('startUpDetails', startup)
    }
  }
})
