<template>
  <div class="bg_img">
    <!-- become invester section -->
    <section class="become_invester">
      <div class="become_invester_main_div">
        <div class="become_invester_col_1">
          <p class="rep_p">
            Empower impact first businesses and <span class="rep_spn">  make a lasting, positive change in the world</span>
            <!-- Invest in social impact brands<br class="rep_nun" />
            and create <span class="rep_spn">long term wealth</span> -->
          </p>
          <div class="become_invester_col_resp">
            <img
              alt="hero"
              class="rep_mruto"
              src="../assets/image/invest-chart.png"
            />
          </div>
          <div class="become_invester_div">
            <div v-if="!loggedIn">
              <router-link to="/login" class="btn-Div">Join now</router-link>
              <p>
                Are you a brand?
                <router-link to="/raise" class="raise_btn">Raise</router-link>
              </p>
            </div>
            <a href="#investStartup" class="btn-Div" v-else>View Deals</a>
          </div>
        </div>
        <div class="become_invester_col_2">
          <img alt="hero" src="../assets/image/invest-chart.png" />
        </div>
      </div>
    </section>
    <!-- become invester section end-->
    <!-- Why Invest section  -->
    <section class="why_invest">
      <!-- heading -->
      <div class="heading head_rep">
        <h1>Why fredo?</h1>
      </div>
      <!-- heading end -->
      <div class="why_invest_main_div">
        <div class="d_flex">
          <!-- card 1 -->
          <div class="why_invest_col">
            <div class="why_invest_box">
              <h1>
                Drive empowerment and impact at scale
              </h1>
            </div>
          </div>
          <!-- card 1 end-->
          <!-- card 2-->
          <div class="why_invest_col">
            <div class="why_invest_box2">
              <h1>
                Access deals vetted by <br/>finance professionals
              </h1>
            </div>
          </div>
          <!-- card 2 end-->
          <!-- card 3-->
          <div class="why_invest_col">
            <div class="why_invest_box">
              <h1>
                Start with as <br/> low as INR 2,00,000
              </h1>
            </div>
          </div>
          <!-- card 3 end-->
        </div>
      </div>
    </section>
    <!-- Why Invest section end -->
    <!-- invest now section -->
    <div v-if="showcomingsoon" class="heading head_rep dealHeadMar">
      <h1>Deals Coming Soon</h1>
      <p class="my-5">Join our exclusive community to get handpicked <br> deals straight to WhatsApp!</p>
      <a href="https://chat.whatsapp.com/EssREgUcIp2GbIZKkgpmZ3" class="btn-Div mt-4" target="_blank">Join Fredo Exclusive Club</a>
      <!-- <h1>Invest now</h1> -->
    </div>
    <!-- invest now section end -->
    <!-- <Companylist v-repeat="Companylist"></Companylist> -->
    <div v-else class="startuplist_main_div" id="investStartup">
      <div class="startuplist_innr_div">
        <startuplist
          v-for="startup in startups"
          :key="startup.id"
          :startupLink="startup.startup_detail.slug"
          :startupName="startup.startup_detail.name"
          :startupLogo="logoPath + startup.startup_detail.logo"
          :startupThumbnail="thumbnailPath + startup.startup_detail.thumbnail"
          :startupShortDesc="startup.startup_detail.description"
          :startupSector="startup.sector.name"
          :rasied="raisedTotal(startup)"
          :leftdays="dayDifference(startup.startup_detail.funding_end_date)"
        />
      </div>
    </div>
  </div>

</template>

<script>
import axios from "axios";
import moment from "moment";

import Startuplist from "../components/Startuplist.vue";
export default {
  // -next-line vue/multi-word-component-names
  name: "Invest",
  components: {
    Startuplist
  },
  data() {
    return {
      startups: [],
      logoPath: process.env.VUE_APP_ASSETS_STARTUP_logo_URL,
      thumbnailPath: process.env.VUE_APP_ASSETS_STARTUP_thumbnail_URL,
      showcomingsoon:false
    };
  },
  computed: {
    loggedIn() {
      return this.$store.getters.loginState;
    },
  },
  methods: {
     dayDifference(date) {
      const endDate = moment(date);
      const today = moment()
      const difference = endDate.diff(today, "days");

      return difference <= 0 ? "Closed" : difference + " Days"
    },
    raisedTotal(startup) {
      const fundingsTotal = startup.fundings.reduce((acc, { investment_amount }) => acc + investment_amount, 0)
      const percentage = (fundingsTotal * 100) / startup.startup_detail.funding_goal
      return percentage.toFixed(0)
    }
  },
  async created() {
    // GET request using axios with async/await
    const response = await axios.get("startup-list");
    this.startups = response.data.data.data;
    if(this.startups.length==0){
      this.showcomingsoon = true;
    }else{
      this.showcomingsoon = false;
    }
  },
};
</script>

<style lang="postcss" scoped>
.bg_img {
  background-image: url("../assets/image/bgstar_invest.png");
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-size: contain;
  overflow: hidden;
  bottom: 0;
}
.become_invester {
  .become_invester_main_div {
    @apply container mx-auto flex px-5 pt-28 pb-12 lg:flex-col;
    .become_invester_col_1 {
      @apply lg:flex-grow md:w-1/2 flex flex-col  md:text-left mb-16 md:mb-0 items-center text-center justify-around;
      p {
        @apply font-bold text-4xl text-white;
        span {
          @apply text-4xl font-bold py-4 text-transparent bg-clip-text bg-gradient-to-br from-gradient to-borderclr;
        }
      }
      .become_invester_div {
        @apply text-center mt-8;
        p {
          @apply mt-2 text-xxs font-normal text-menutext;
          .raise_btn {
            @apply text-transparent bg-clip-text border-0  border-b-2	 border-gradient bg-gradient-to-br from-gradient to-borderclr;
          }
        }
      }
    }
    .become_invester_col_2 {
      @apply lg:max-w-lg lg:w-full md:w-1/2 w-5/6;
      img {
        @apply object-cover object-center rounded m-auto;
      }
    }
  }
}
.why_invest {
  .why_invest_main_div {
    @apply container px-5 mb-10 mx-auto;
    .d_flex {
      @apply flex flex-wrap items-stretch mb-20;
      .why_invest_col {
        @apply p-6 lg:w-1/3;
        .why_invest_box {
          @apply h-full bg-cardbg2 bg-opacity-75  flex justify-center items-center pt-8 pb-8 rounded-[1.5rem] overflow-hidden text-center relative;
          h1 {
            @apply sm:text-2xl text-4xl font-medium text-menutext mb-3 px-4;
          }
        }
        /* this div need upper space so that reason create a new div */
        .why_invest_box2 {
          @apply h-full bg-cardbg2 bg-opacity-75 flex justify-center items-center  py-8 rounded-[1.5rem] overflow-hidden text-center relative;
          h1 {
            @apply sm:text-2xl text-4xl font-medium text-menutext  mb-3;
          }
        }
      }
    }
  }
}

.startuplist_main_div {
  @apply flex items-center;
  .startuplist_innr_div {
    @apply container ml-auto mr-auto flex flex-wrap items-stretch;
  }
}

.heading {
  @apply flex flex-wrap w-full mb-10 flex-col items-center text-center;
  h1 {
    @apply text-6xl font-bold py-4 text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
  }
}
@media (min-width: 320px) and (max-width: 552px) {
  .heading h1 {
    @apply text-[1.875rem] font-bold !important;
  }
}

.dealHeadMar{
  margin-bottom: 5rem;
  p{
    font-size: 24px;
    color: #fff;
  }
}



@media (min-width: 300px) and (max-width: 552px){
.dealHeadMar {
    margin-bottom: 3rem !important;
}
}
</style>
