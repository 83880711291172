<template>
  <!-- how ti works -->
  <div class="container">
    <!-- heading -->
          <div class="heading">
              <h1>How it Works</h1>
          </div>
    <!-- heading end -->
    <!-- button -->
    <div class="tabs" id="tabs">
      <input type="radio" id="radio-1" name="tabs" @click="activetab='1'" :class="[ activetab == '1' ? 'active' : '' ]">
      <label class="tab" for="radio-1">Investors</label>
      <input type="radio" id="radio-2" name="tabs" @click="activetab='2'" :class="[ activetab == '2' ? 'active' : '' ]">
      <label class="tab" for="radio-2">Brands</label>
      <span class="glider"></span>
	</div>
    <!-- button  end-->
    <!-- how it works section -->
        <div v-if="activetab ==='1'">
          <!-- timeline -->
          <div class="timeline_main_div" >
              <div class="top_main_box">
                <div class="top_dot">
                  <div class="box-list-top" >
                      <h2>Sign up and complete KYC</h2>
                  </div>
                </div>
                <div></div>
                <div class="top_dot">
                  <div class="box-list-top">
                      <h2 class="respo_mob_hide">Empower your favourite brand</h2>
                      <h2 class="respo_dsk_hide"><router-link to="/invest">Dive deep into deals</router-link></h2>
                  </div>
                </div>

                <div></div>
              </div>
              <!-- line -->
              <div class="progres_bar_point"></div>
              <!-- line end -->
              <div class="btom_main_box">
                <div></div>
                <div class="botm_dot">
                <div class="box-list-botm">
                    <h2 class="respo_mob_hide"><router-link to="/invest">Dive deep into deals</router-link></h2>
                    <h2 class="respo_dsk_hide">Empower your favourite brand</h2>
                </div>

                </div>
                <div></div>
                <div class="botm_dot">
                <div class="box-list-botm">
                    <h2>Sign the agreement</h2>
                </div>
                </div>

              </div>
          </div>
          <!-- timeline end -->
          <!-- how it works section emd -->
          <!-- step -->
          <div class="step_main">
            <!-- step 1   -->
            <div class="step_div">
                  <!-- heading -->
                  <div class="step">
                      <h4>Sign up and Complete your KYC</h4>
                  </div>
                  <!-- heading end -->
                  <!-- details -->
                  <div class="detail">
                    <p>It takes less than a minute to sign up. Click here to <router-link to="/login">sign up</router-link> now. Before you start investing, understand and acknowledge the risks involved and complete a quick KYC. Note: Keep your PAN and Aadhar Card handy.
                    </p>
                  </div>
              <!-- details end -->
            </div>
            <!-- step 1 end   -->
            <!-- step 2   -->
            <div class="step_div">
              <!-- heading -->
                    <div class="step">
                        <h4>Dive deep into deals</h4>
                    </div>
              <!-- heading end -->
              <!-- details -->
              <div class="detail">
                <p>Don your Sherlock hat. You should know as much as possible about the brand before making an investment decision.</p>
              </div>
              <!-- details end -->
            </div>
            <!-- step 2 end   -->
            <!-- step 3   -->
            <div class="step_div">
              <!-- heading -->
                    <div class="step">
                        <h4>Empower your favourite brand</h4>
                    </div>
              <!-- heading end -->
              <!-- details -->
              <div class="detail">
                <p>Agree to the terms and complete the payment process through our payment gateway. Your money gets safely deposited in an Escrow Account with a Scheduled Bank till the closure of the deal.</p>
              </div>
              <!-- details end -->
            </div>
            <!-- step 3 end   -->
            <!-- step 4   -->
            <div class="step_div">
              <!-- heading -->
                    <div class="step">
                        <h4>Sign the agreement and watch your money grow!</h4>
                    </div>
              <!-- heading end -->
              <!-- details -->
              <div class="detail">
                <p>Sign the agreements digitally and get your securities as soon as compliance is complete. Then just sit back and see the magic!</p>
              </div>
              <!-- details end -->
            </div>
            <!-- step 4 end   -->
          </div>
          <!-- step end -->
        </div>
        <div v-if="activetab ==='2'">
          <!-- timeline -->
          <div class="timeline_main_div" >
              <div class="top_main_box">
                <div class="top_dot">
                  <div class="box-list-top" >
                      <h2>Apply to raise funds</h2>
                  </div>
                </div>
                <div></div>
                <div class="top_dot">
                  <div class="box-list-top">
                       <h2 class="respo_mob_hide">Go live on Fredo</h2>
                       <h2 class="respo_dsk_hide">Answer follow-up questions</h2>
                  </div>
                </div>
                <div></div>
              </div>
              <!-- line -->
              <div class="progres_bar_point"></div>
              <!-- line end -->
              <div class="btom_main_box">
                <div></div>
                <div class="botm_dot">
                <div class="box-list-botm">
                    <h2 class="respo_mob_hide">Answer follow-up questions</h2>
                    <h2 class="respo_dsk_hide">Go live on Fredo</h2>
                </div>
                </div>
                <div></div>
                <div class="botm_dot">
                <div class="box-list-botm">
                    <h2>Get funds in the bank</h2>
                </div>
                </div>

              </div>
          </div>
          <!-- timeline end -->
          <!-- how it works section emd -->
          <!-- step -->
          <div class="step_main hideBrands">
            <!-- step 1   -->
            <div class="step_div">
                  <!-- heading -->
                  <div class="step">
                      <h1>Revenue Based Finance</h1>
                      <h2>RBF is a non-dilutive, flexible way to grow your brand.</h2>
                      <h3>As a founder, you don’t have to:</h3>
                  </div>
                  <!-- heading end -->
                  <!-- details -->
                  <div class="detail">
                    <p>- Dilute equity for ad spends</p>
                    <p>- Wait 2-2.5 months for the money to hit your accounts</p>
                    <p>- Waste time in working on compliance</p>
                  </div>
                  <div class="step">
                      <h3>You get to focus on what you do best – run the show!</h3>
                      <p>RBF is a friendly source of financing for companies as it does not come with fixed EMIs that traditional bankers offer. Plus, there’s no need for a personal guarantee from the founders!</p>
                  </div>
              <!-- details end -->
            </div>
            <!-- step 1 end   -->
            <!-- step 2   -->
            <div class="step_div">
                  <!-- heading -->
                  <div class="step">
                      <h2>How do you repay the amount?</h2>
                      <h3>As a percentage of monthly revenue (5-20%). Basically, you pay as you go (grow) and the process is damn simple.</h3>
                  </div>
                  <!-- heading end -->
                  <!-- details -->
                  <div class="detail">
                    <p>- Fill out a quick form -> Get your term sheet</p>
                    <p>- Share your online sales data</p>
                    <p>- Get funds in 7-20 days</p>
                    <p>- Repay with flexibility (as a % of revenue)</p>
                  </div>
              <!-- details end -->
            </div>
            <!-- step 2 end   -->
            <!-- step 3   -->
            <div class="step_div">
                  <!-- heading -->
                  <div class="step">
                      <h2>Why do we do this?</h2>
                  </div>
                  <!-- heading end -->
                  <!-- details -->
                  <div class="detail">
                    <p>We understand how crucial equity (and sometimes control) is for founders. This alternative source of finance is to help you grow faster without having to dilute precious equity or negotiate unfavourable terms with investors.</p>
                  </div>
              <!-- details end -->
            </div>
            <!-- step 3 end   -->
            <!-- step 4   -->
            <div class="step_div">
                  <!-- heading -->
                  <div class="step">
                      <h2>RBF is best suited for brands with:</h2>
                  </div>
                  <!-- heading end -->
                  <!-- details -->
                  <div class="detail">
                    <p>- Gross Margins > 40%</p>
                    <p>- Return on Ad Spends (RoAS) > 1.5x</p>
                    <p>- Current Runway > 6 months</p>
                    <p>- Revenue generating > 12 months</p>
                  </div>
              <!-- details end -->
            </div>
            <!-- step 4 end   -->
            <!-- step 5   -->
            <div class="step_div">
                  <!-- heading -->
                  <div class="step">
                      <h2 class="whitetxt">Equity</h2>
                  </div>
                  <!-- heading end -->
                  <!-- details -->
                  <div class="detail">
                    <p>The average time from thinking about raising pre-seed or seed funds to the funds actually being deployed for growth is ~2.5-3 months in India. Plus, it comes with its own set of documents, compliance headaches and whatnot.</p><br/>
                    <p>We have created processes and automation that can help you do away with the whole compliance burden, and reduce the time it takes for money to hit your accounts to 15-30 days. We want founders to focus on what they do best - and leave all their compliance burden to us!</p>
                  </div>
              <!-- details end -->
            </div>
            <!-- step 5 end   -->

          </div>
          <!-- step end -->
        </div>
  </div>
  <!-- how ti works end -->
</template>

<script>
export default {
 // -next-line vue/multi-word-component-names
 name:'Howitworks',
 data() {
   return {
     isActive: false,
      el: '#tabs',
      activetab: 1,
    };
  },
    methods: {
      toggle() {
        this.isActive = this.isActive ? false : true;
      },
    },
  mounted(){
    if(this.$route.query.activetab == 'investors'){
      this.activetab='1'
    }else if(this.$route.query.activetab == 'brands'){
      this.activetab='2'
    }else{
      this.activetab='1'
    }
  }

}
</script>
<style lang="postcss" scoped>
.heading{ @apply flex flex-wrap w-full mb-10 flex-col items-center text-center;
    h1{ @apply text-6xl font-bold py-4 text-transparent bg-clip-text bg-gradient-to-br from-invbtn to-invbtn2;
  }
}
@media (min-width: 320px) and (max-width: 552px){
  .heading{
   @apply flex flex-wrap w-full mb-10 flex-col items-center text-center;
    h1{ @apply text-3xl font-bold py-4 text-transparent bg-clip-text bg-gradient-to-br from-invbtn to-invbtn2 !important;
    }
  }
}
.btn{ @apply flex w-1/2 bg-cardbg2 items-center justify-center;
 .btn-1{ @apply  text-white opacity-75  text-2xl tracking-wide py-7 px-20 rounded-l-full hover:bg-gradient-to-br from-clr6 to-clr7  hover:text-black;
  }
 .btn-2{ @apply  text-white opacity-75  text-2xl tracking-wide py-7 px-20 rounded-r-full hover:bg-gradient-to-br from-clr6 to-clr7 hover:text-black;
  }
}
.timeline_main_div{ @apply container mb-20;
  .top_main_box{ @apply grid grid-cols-4 gap-4 mt-24;
    .top_dot{ @apply relative after:w-8 after:h-8 after:absolute after:bg-borderclr after:shadow-cstm after:left-[44%] after:right-1/2 after:-bottom-20 after:rounded-full after:z-[1];
      .box-list-top{ @apply h-full bg-cardbg3 bg-opacity-75 px-8 py-12 flex justify-center items-center rounded-[1rem]  text-center relative before:absolute before:-bottom-3 before:left-0 before:h-7 before:w-1/2 before:bg-cardbg3 before:transform before:skew-y-12 before:rounded-bl-lg after:absolute after:-bottom-3 after:right-0 after:h-7 after:w-1/2 after:bg-cardbg3 after:transform after:-skew-y-12 after:rounded-br-lg ;
        h2{ @apply text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
        }
        p{ @apply text-white opacity-75 mb-3;
        }
      }
    }
  }
  .progres_bar_point{ @apply relative w-auto h-3 bg-borderclr rounded-full mt-14 mb-14 mx-36 shadow-cstm;
  }
  .btom_main_box{ @apply grid grid-cols-4 gap-4;
    .botm_dot{ @apply relative after:w-8 after:h-8 after:absolute after:bg-borderclr after:shadow-cstm after:left-[44%] after:right-1/2 after:-top-20 after:rounded-full after:z-10;
      .box-list-botm{ @apply h-full bg-cardbg3 bg-opacity-75 px-8 py-12 flex justify-center items-center  rounded-[1rem]  text-center relative before:absolute before:-top-3 before:left-0 before:h-7 before:w-1/2 before:bg-cardbg3 before:transform before:-skew-y-12 before:rounded-tl-lg after:absolute after:-top-3 after:right-0 after:h-7 after:w-1/2 after:bg-cardbg3 after:transform after:skew-y-12 after:rounded-tr-lg;
        h2{ @apply text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
        }
        p{ @apply text-white opacity-75 mb-3;
        }
      }
    }
  }
}
.step_main{ @apply container mb-52;
  .step_div{
    .step{ @apply  w-full flex-col items-center text-left;
      h1{ @apply text-[1.6rem] font-bold pb-0 pt-2 text-white;
      }
      h2{
        @apply text-[1.4rem] font-semibold pt-5 text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
      }
      h3{
        @apply text-base font-medium py-2 text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
      }
       h4{ @apply text-[1.6rem] font-bold text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
      }
      p{ @apply text-white opacity-75}
      .whitetxt{
        @apply text-white font-medium !important;
      }
    }
    .detail{ @apply w-full mb-5;
      p{
        @apply text-white opacity-75;
        a{
          @apply font-bold text-transparent bg-clip-text bg-gradient-to-br from-clr6 to-clr7;
          }
      }
    }
  }
}
.respo_dsk_hide{
  display: none;
}

.tabs {
  display: flex;
  justify-content: center;
  max-width: 40%;
  margin: auto;
  position: relative;
  background-color:#1F1F1F;
  padding: 0.75rem;
  border-radius: 2.5rem;
}
.tabs * {
  z-index: 2;
}
input[type=radio] {
  display: none;
}
.tab {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 54px;
  width: 230px;
  font-size: 1.25rem;
  font-weight: 500;
  border-radius: 99px;
  cursor: pointer;
  transition: color 0.15s ease-in;
}
.tabs label{
  color:#ACACAC;
  font-size: 1.5rem;
}
input[type=radio].active + label {
  color: #000000;
  font-size: 1.5rem;
}
input[id=radio-1].active ~ .glider {
  transform: translateX(0);
}
input[id=radio-2].active ~ .glider {
  transform: translateX(100%);
}
.glider {
  position: absolute;
  display: flex;
  height: 78px;
  width: 50%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--secondary-color);
  background-image: linear-gradient(90deg, rgba(66,214,164,1) 0%, rgba(59,208,232,1) 100%);
  z-index: 1;
  border-radius: 99px;
  transition: 0.25s ease-out;
}
.hideBrands{
  @apply hidden;
}
</style>
