import store from '../store/index'
import axios from 'axios'

export const tokenGuard = async () => {
  store.commit('setGlobalLoadingState', true)
  const token = localStorage.getItem('Token')

  await axios
    .get('login_user', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(async (res) => {
      if (res.status === 200) {
        const details = {
          ...res.data.user,
          data: {
            token
          }
        }

        store.commit('setGlobalLoadingState', false)
        store.commit('login', { loginSuccess: true, details })
      }
    })
    .catch((err) => {
      store.commit('setGlobalLoadingState', false)
      store.commit('logout')
    })
}
