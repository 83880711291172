import { createWebHistory, createRouter } from 'vue-router'
import store from './store'
import Home from './views/Home.vue'
import Invest from './views/Invest.vue'
import Raise from './views/Raise.vue'
import Howitworks from './views/Howitworks.vue'
import Startuplist from './components/Startuplist.vue'
import Startup from './components/BKLASTStartup.vue'
import Login from './components/Login.vue'
import Payment from './views/Payment.vue'
import Kyc from './views/Kyc.vue'
import Careers from './views/Careers.vue'
import Error from './views/Errorpage.vue'
import Privacypolicy from './views/Privacypolicy.vue'
import RiskofDeclaration from './views/RiskofDeclaration.vue'
import TermsandConditions from './views/TermsandConditions.vue'
import Dashboard from './views/Dashboard/Dashboard.vue'
import GenerateInvoice from './views/generateInvoice.vue'
import { fetchUser } from './utils/fetchUser'
import { tokenGuard } from './utils/tokenGuard'

const routes = [
  {
    name: 'Home',
    path: '/',
    component: Home
  },
  {
    name: 'Invest',
    path: '/invest',
    component: Invest
  },
  {
    name: 'Raise',
    path: '/raise',
    component: Raise
  },
  {
    name: 'Howitworks',
    path: '/howitworks',
    component: Howitworks
  },
  {
    name: 'Startuplist ',
    path: '/startuplist ',
    component: Startuplist
  },
  {
    name: 'Startup',
    path: '/startup/:id',
    component: Startup
  },
  {
    name: "PaymentStatus",
    path: "/payment-status",
    component: () => import("./views/PaymentStatus"),
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (!store.getters.loginState) {
        next()
      } else if (!store.getters.kycStatus) {
        next('/kyc')
      } else {
        next('/profile')
      }
    }
  },
  {
    name: 'Payment',
    path: '/payment',
    component: Payment,    
    beforeEnter: async (to, from, next) => {
      await tokenGuard()

      if (!store.getters.loginState) {
        next('/login')
      } else if (!store.getters.kycStatus) {
        next('/kyc')
      } else {
        next()
      }
    }
  },
  {
    // replace path with "/payment/get-payment-detail" when final
    name: "GetPaymentDetail",
    path: "/get-payment-detail",
    component: () => import("./views/GetPaymentDetail")
  },
  {
    name: 'Kyc',
    path: '/kyc',
    component: Kyc,
    beforeEnter: async (to, from, next) => {
      await tokenGuard()

      if (!store.getters.loginState) {
        next('/login')
      } else {
        next()
      }
    }
  },
  {
    name: 'Careers',
    path: '/careers',
    component: Careers
  },
  {
    name: 'Privacypolicy',
    path: '/privacypolicy',
    component: Privacypolicy
  },
  {
    name: 'GenerateInvoice',
    path: '/generate-invoice',
    component: GenerateInvoice
  },
  {
    name: 'RiskofDeclaration',
    path: '/riskofdeclaration',
    component: RiskofDeclaration
  },
  {
    name: 'TermsandConditions',
    path: '/terms-and-conditions',
    component: TermsandConditions
  },
  {
    path: '/views/Dashboard',
    component: Dashboard,
    children: [
      {
        path: '/profile',
        component: () => import('./views/Dashboard/Profile.vue')
      },
      {
        path: '/profile/setting',
        component: () => import('./views/Dashboard/Settings.vue')
      }
    ],
    async beforeEnter(to, from, next) {
      await tokenGuard()

      if (!store.getters.loginState) {
        next('/login')
      } else {
        next()
      }
    }
  },
  { path: '/:pathMatch(.*)', component: Error }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }
})

router.beforeEach(async (to, from) => {
  store.commit('setGlobalLoadingState', true)

  await fetchUser().then((res) => {
    if (res === 'no-token') {
      localStorage.clear()
    }

    store.commit('setGlobalLoadingState', false)
  })
})

export default router
